<template>
  <div class="delBtn">
    <el-popover placement="top" width="160" v-model="visible">
      <p>确定删除吗此消息模板吗？</p>
      <div style="text-align: right; margin: 0">
        <el-button size="mini" type="text" @click="visible = false">取消</el-button>
        <el-button type="primary" size="mini" @click="delHandle" :loading="loading">确定</el-button>
      </div>
      <ColorTextBtn slot="reference" type="danger" size="small">删除</ColorTextBtn>
    </el-popover>
  </div>
</template>

<script>
import { deleteTemplate } from '@/api/messageApi'
export default {
  components: {},
  props: {
    row: {
      type: Object,
      default: () => {
        return {}
      }
    },
    init: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      visible: false,
      loading: false
    }
  },
  computed: {},
  methods: {
    async delHandle() {
      this.loading = true
      const { code } = await deleteTemplate({ idList: [this.row.id] })
      if (code === 0) {
        this.$message.success('操作成功')
      } else {
        this.$message.error('操作失败')
      }
      this.init()
      this.loading = false
    }
  }
}
</script>

<style scoped lang="scss">
.delBtn {
  display: inline-block;
}
</style>
