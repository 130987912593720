import request from '@/service/request'
import { getXMenuType } from '@/utils/constant'
// 删除模板
export function deleteTemplate(data = {}) {
  return request({
    url: '/externaladmin/messageService/messageTemplate/delete',
    method: 'post',
    data
  })
}
// 编辑模板
export function updateTemplate(data = {}) {
  return request({
    url: '/externaladmin/messageService/messageTemplate/update',
    method: 'post',
    data
  })
}
// 创建模板
export function createTemplate(data = {}) {
  return request({
    url: '/externaladmin/messageService/messageTemplate/create',
    method: 'post',
    data
  })
}
// 根据ID查询模板
export function getTemplateById(data = {}) {
  return request({
    url: '/externaladmin/messageService/messageTemplate/getById',
    method: 'post',
    data
  })
}
